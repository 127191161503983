import styled from "@emotion/styled";
import { Quicksand } from "next/font/google";
import { ReactNode } from "react";
import { Footer } from "ui";

import Header from "./Header";

const inter = Quicksand({ subsets: [], display: "swap" });

const Wrapper = styled.main`
  max-width: 1000px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1;
  padding: 50px 12px 120px;
  height: 100%;
`;

export default function Layout({
  children,
  headerLinks = true,
}: {
  children: ReactNode;
  headerLinks?: boolean;
}) {
  return (
    <Wrapper className={inter.className}>
      <Header headerLinks={headerLinks} />
      <Main>{children}</Main>
      <Footer
        links={[
          { href: "/terms", label: "Terms & Conditions" },
          { href: "/privacy", label: "Privacy Policy" },
        ]}
      />
    </Wrapper>
  );
}
