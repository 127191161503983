import styled from "@emotion/styled";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useUserAuthContext } from "context/UserAuthContext";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { Button, colors, Link, logo } from "ui";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
`;

const HeaderLinkGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

const Header = ({ headerLinks }: { headerLinks: boolean }) => {
  const { user, logout } = useUserAuthContext();
  const { push } = useRouter();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Link href="/">
        <Image src={logo} alt="logo" height={40} />
      </Link>
      {headerLinks && (
        <HeaderLinkGroup>
          {user?.issuer ? (
            <>
              <IconButton
                aria-label="account circle"
                size="small"
                onClick={handleClick}
                sx={{ color: colors.pink600 }}
              >
                <AccountCircle fontSize="large" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                disableScrollLock
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    push("/user");
                  }}
                >
                  Profil
                </MenuItem>
                <MenuItem onClick={logout}>Logga ut</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button
                aria-label="logga in"
                variant="text"
                size="small"
                onClick={() => push("/login")}
              >
                Logga in
              </Button>
            </>
          )}
        </HeaderLinkGroup>
      )}
    </Wrapper>
  );
};

export default Header;
